<template>
  <div class="pageWrapper">
    <Loader v-if="!group" :color="'#444'" />
    <div v-else>
      <PrevPage title="Back to groups" />
      <SaveDiscardMenu
        :discardChanges="discardChanges"
        :saveChanges="saveChanges"
        :isChanged="isChanged"
        :title="group.group_name"
        :displayDelete="true"
        :delete="{
          message: 'Are you sure you want to delete ' + group.group_name + '?',
          execute: deleteGroup,
        }"
      />
      <MessageBox
        :data="{
          transition: 0.2,
          height: '4vw',
          close: { auto: true, time: 5 },
        }"
      />
      <div class="divider">
        <div>
          <InfoWrapperBox>
            <div class="productsHeader">
              <SearchInput
                placeholder="Search for a product"
                v-model="searchString"
              />
            </div>
            <div class="productWrapper">
              <div
                @click="toggleSelectProduct(product)"
                class="productItem"
                :key="i"
                v-for="(product, i) in allProductsFiltered"
                :class="isSelected(product.id)"
              >
                {{ product.productName }}
              </div>
            </div>
          </InfoWrapperBox>
        </div>
        <div>
          <InfoWrapperBox>
            <H4>Group name</H4>
            <StandardInput
              placeholder="Group name"
              v-model="group.group_name"
            />
            <br /><br />
            <H4>Show in webshop</H4>
            <md-switch v-model="showInWebshop" />
          </InfoWrapperBox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

import Loader from '@/components/Loader'
import SaveDiscardMenu from '../../components/SaveDiscardMenu.vue'
import PrevPage from '../../components/PrevPage.vue'
import MessageBox from '../../components/MessageBox.vue'
import InfoWrapperBox from '../../layout/InfoWrapperBox.vue'
import StandardInput from '../../layout/StandardInput.vue'
import H4 from '../../layout/typography/H4.vue'
import SearchInput from '../../layout/SearchInput.vue'

export default {
  components: {
    Loader,
    SaveDiscardMenu,
    PrevPage,
    MessageBox,
    InfoWrapperBox,
    StandardInput,
    H4,
    SearchInput,
  },
  data() {
    return {
      group: undefined,
      baseGroup: undefined,
      allProducts: [],
      searchString: '',
    }
  },
  watch: {
    searchString() {
      this.sortAllProducts()
    },
  },
  computed: {
    allProductsFiltered() {
      return this.allProducts.filter((product) => {
        if (
          product.productName
            .toLowerCase()
            .includes(this.searchString.toLowerCase()) ||
          this.group.products.find((el) => el.id === product.id)
        ) {
          return product
        }
      })
    },
    isChanged() {
      if (JSON.stringify(this.group) == JSON.stringify(this.baseGroup)) {
        return false
      }
      return true
    },
    showInWebshop: {
      get() {
        if (this.group.webshop == 1) {
          return true
        }
        return false
      },
      set(newValue) {
        if (newValue) {
          this.group.webshop = 1
        } else {
          this.group.webshop = 0
        }
      },
    },
  },
  methods: {
    toggleSelectProduct(product) {
      let index = this.group.products.findIndex((el) => el.id === product.id)

      if (index === -1) {
        this.group.products.push({
          productName: product.productName,
          id: product.id,
        })
      } else {
        this.group.products.splice(index, 1)
      }
    },
    isSelected(productId) {
      let index = this.group.products.findIndex(
        (product) => product.id === productId
      )
      if (index != -1) {
        return 'selectedProduct'
      }
    },
    deleteGroup() {
      axios
        .post('groups/deleteGroup', {
          groupId: this.group.id,
        })
        .then((res) => {
          this.$router.push({ name: 'Groups' })
        })
    },
    saveChanges() {
      axios
        .post('groups/updateGroup', {
          groupId: this.group.id,
          groupName: this.group.group_name,
          webshop: this.group.webshop,
          products: this.group.products,
        })
        .then((res) => {
          this.baseGroup = JSON.parse(JSON.stringify(this.group))
          this.$store.commit('SET_MESSAGEBOX', { message: 'You have successfully updated ' + this.group.group_name + '!', type: 'success' })
        })
        .catch((err) => {
          console.log(err)
        })
      this.sortAllProducts()
    },
    discardChanges() {
      this.group = JSON.parse(JSON.stringify(this.baseGroup))
    },
    sortAllProducts() {
      this.allProducts.sort((a, b) => {
        if (this.group.products.find((product) => product.id === a.id)) {
          return -1
        } else {
          return 1
        }
      })
    },
  },
  created() {
    axios
      .post('groups/getGroup', {
        groupId: this.$route.params.groupId,
      })
      .then((res) => {
        this.group = JSON.parse(JSON.stringify(res.data))
        this.baseGroup = JSON.parse(JSON.stringify(res.data))

        axios.post('groups/getAllProducts').then((res) => {
          this.allProducts = JSON.parse(JSON.stringify(res.data))
          this.sortAllProducts()
        })
      })
  },
}
</script>

<style scoped lang="scss">
.divider {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 70% minmax(30%, 200px);
  grid-gap: 20px;
}

.productsHeader {
  margin-bottom: 10px;
}
.md-switch {
  display: block;
}

.fa-trash-alt {
  margin: 8px;
  cursor: pointer;
  color: #777;
}

.fa-trash-alt:hover {
  color: black;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

.productWrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
}

.productItem {
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
  background: #fbfbfb;
  text-align: left;
  -webkit-box-shadow: 0 0 10px -5px #b8b8b8;
  box-shadow: 0 0 10px -5px #b8b8b8;
  width: auto;
  display: block;
  cursor: pointer;
  border: 1px solid transparent;
  user-select: none;
}

.productItem:hover {
  background: #f7f7f7;
}

.productSearchInput {
  background: none;
  border: none;
  padding-left: 10px;
  top: -3px;
  font-size: 10pt;
  position: relative;
}

.selectedProduct {
  border: 1px solid rgb(55, 121, 182);
}

@media screen and (max-width: 700px) {
  .pageWrapper {
    margin: auto;
    width: 100%;
  }
  .divider {
    position: relative;
    width: 100%;
    display: inline;
  }
}
</style>