var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pageWrapper"},[(!_vm.group)?_c('Loader',{attrs:{"color":'#444'}}):_c('div',[_c('PrevPage',{attrs:{"title":"Back to groups"}}),_c('SaveDiscardMenu',{attrs:{"discardChanges":_vm.discardChanges,"saveChanges":_vm.saveChanges,"isChanged":_vm.isChanged,"title":_vm.group.group_name,"displayDelete":true,"delete":{
        message: 'Are you sure you want to delete ' + _vm.group.group_name + '?',
        execute: _vm.deleteGroup,
      }}}),_c('MessageBox',{attrs:{"data":{
        transition: 0.2,
        height: '4vw',
        close: { auto: true, time: 5 },
      }}}),_c('div',{staticClass:"divider"},[_c('div',[_c('InfoWrapperBox',[_c('div',{staticClass:"productsHeader"},[_c('SearchInput',{attrs:{"placeholder":"Search for a product"},model:{value:(_vm.searchString),callback:function ($$v) {_vm.searchString=$$v},expression:"searchString"}})],1),_c('div',{staticClass:"productWrapper"},_vm._l((_vm.allProductsFiltered),function(product,i){return _c('div',{key:i,staticClass:"productItem",class:_vm.isSelected(product.id),on:{"click":function($event){return _vm.toggleSelectProduct(product)}}},[_vm._v(" "+_vm._s(product.productName)+" ")])}),0)])],1),_c('div',[_c('InfoWrapperBox',[_c('H4',[_vm._v("Group name")]),_c('StandardInput',{attrs:{"placeholder":"Group name"},model:{value:(_vm.group.group_name),callback:function ($$v) {_vm.$set(_vm.group, "group_name", $$v)},expression:"group.group_name"}}),_c('br'),_c('br'),_c('H4',[_vm._v("Show in webshop")]),_c('md-switch',{model:{value:(_vm.showInWebshop),callback:function ($$v) {_vm.showInWebshop=$$v},expression:"showInWebshop"}})],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }